<template>
  <div class="profile-config-form__field col-md-4">
    <div class="profile-config-form__field-title">{{ __('profile_config_form.shoe_sole_length') }}</div>

    <validation-provider
        tag="span"
        :vid="`shoe_sole_length-${_uid}`"
        :name="__('profile_config_form.shoe_sole_length')"
        rules="numeric|between:100,400"
        v-slot="{ errors }"
    >
      <input type="number"
             class="input input--profile"
             v-model="value"
             :placeholder="__('profile_config_form.optional_placeholder')"
      />
      <span v-if="errors.length > 0" class="input__error">{{ errors[0] }}</span>
    </validation-provider>
  </div>
</template>

<script>
import ProfileConfigFormField from '@/mixins/profile-config-form-field';
import Locales from '@/mixins/locales';

export default {
  name: 'shoe_sole_length',
  mixins: [
    Locales,
    ProfileConfigFormField('shoe_sole_length', ''),
  ],
};
</script>
